<template>
	<div class="bg-F7F7F7">
		<div  class="relative" >
			<!-- <img class=" w100 display-block" style="width: 1920px;height:600 ;" :src="dataDetail.pc_image" alt="" /> -->
				<el-image class="" style="width:100%;height:600px;"  :src="dataDetail.pc_image||dataDetail.bg_image"  fit="cover" lazy></el-image>
		</div>
		
		<div class="bg-white main relative">
			<div style="opacity: 0;">
				<div class="fz22 co-333333 align-items-center">
					<span>{{dataDetail.title|| ''}}</span>
					<div class="follow-btn center mart10 cure-pointer" @click="follow">
						<!-- <img class="folloe_icon display-block" src="@/assets/img/folloe_icon.png" alt="" />
						<span class="fz14 co-010085">{{isfollow ? '已关注' : '关注'}}</span> -->
					</div>
				</div>
				<div class="justify-content-center-between">
					<span class="fz16 co-333333">白塔-画室界的黄埔军校</span>
					<div class="sign-btn center fz16 co-010085 cure-pointer" @click="online">在线报名</div>
				</div>
				<div class="align-items-end paddlr20">
					<span class="fz12 co-999999">浏览</span>
					<span class="fz12 co-333333 display-block marl8">790</span>
					<span class="fz12 co-999999">推荐</span>
					<span class="fz12 co-333333 display-block marl8">790</span>
					<span class="fz12 co-999999">报名</span>
					<span class="fz12 co-333333 display-block marl8">790</span>
				</div>
				<div class="w100 boderb1-E6E6E6 paddt20 bg-white"></div>
				<div class="h44 paddlr20 justify-content-center-between bg-white">
					<div class="align-items-center">
						<img class="su_01 display-block" src="@/assets/img/su_01.png" alt="" />
						<span class="fz14 co-333333 marl8">北大北京校区A：广东省广州市天河区街道办事处200a</span>
					</div>
					<div class="align-items-center">
						<img class="de-04 display-block marr32" src="@/assets/img/de_04.png" />
						<img class="de-04 display-block marr32" src="@/assets/img/de_02.png" />
						<img class="de-04 display-block marr32" src="@/assets/img/de_05.png" />
						<img class="de-04 display-block" src="@/assets/img/de_06.png" />
					</div>
				</div>
			</div>
			<div class="absolute left0 right0 top0 bottom0 paddt10">
				<div class="display-flex">
					<div class="marl20 " style="position: relative; top: -30px;">
						<!-- <img class="img_02 display-block" :src="teacherInfo.avatar" /> -->
						<el-image class="" style="width:80px;height: 80px;"  :src="dataDetail.image" lazy></el-image>
					</div>
					<div class=" display-flex flex-column  ">
					<div class="fz22 co-333333 align-items-center paddl20 marb15">
						<span class="one-point 	" style="width: 780px;">{{dataDetail.title|| ''}}</span>
						<div class="follow-btn center cure-pointer marl20" @click="follow">
							<img class="folloe_icon display-block" src="@/assets/img/folloe_icon.png" alt="" />
							<span class="fz14 co-010085">{{isfollow ? '已关注' : '关注'}}</span>
						</div>
					</div>
					<div class="justify-content-center-between paddl15  marb20  paddl20">

						<span class="fz16 co-333333 mart10 marr12"
							style="line-height: 25px;width: 770px;">{{dataDetail.mini_title}}</span>
						<div class="sign-btn center fz16 co-010085 cure-pointer marr10" @click="openadmin">在线咨询
						</div>
						<div class="sign-btn center fz16 co-010085 cure-pointer "  @click="online">在线报名</div>
					</div>
					</div>
				</div>
				<div class="align-items-end paddlr20 ">
					<span class="fz12 co-999999">浏览</span>
					<span class="fz16 l16 co-333333 display-block marl8">{{dataDetail.view|| ''}}</span>
					<span class="fz12 marl26 co-999999">推荐</span>
					<span class="fz16 l16 co-333333 display-block marl8">{{dataDetail.favorite||'0'}}</span>
					<span class="fz12 marl26 co-999999">报名</span>
					<span class="fz16 l16 co-333333 display-block marl8">{{dataDetail.sign_num|| ''}}</span>
				</div>
				<div class="w100 boderb1-E6E6E6 paddt20 bg-white"></div>
				<div class="h44 paddlr20 justify-content-center-between bg-white">
					<div class="align-items-center">
						<img class="su_01 display-block" src="@/assets/img/su_01.png" alt="" />
						<span class="fz14 co-333333 marl8 one-point"
							style="width: 900px;">{{dataDetail.address[0]}}</span>
					</div>
					<div class="align-items-center cure-pointer" @click="rightClick">
						<img class="de-04 display-block marr32" src="@/assets/img/de_04.png" />
						<img class="de-04 display-block marr32" src="@/assets/img/de_02.png" />
						<img class="de-04 display-block marr32" src="@/assets/img/de_05.png" />
						<img class="de-04 display-block" src="@/assets/img/de_06.png" />
					</div>
				</div>
			</div>
		</div>

		<div class="mart10 center">
			<div class="w1200 fz16 co-333333 bg-white">
				<div class="paddlr20 align-items-center h40">福利介绍</div>
				<div class="w100">
					<div class="paddlr20 label-area flex-wrap">
						<div class="label-item center fz14 co-010085 marr10" v-for="(item, i) in  dataDetail.welfare"
							:key="i">{{item}}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="mart10 center">
			<div class="w1200 fz16 co-333333 bg-white paddt20 paddb20  cure-pointer" @click="godetails(dataDetail.id)">
				<div class="paddlr20 justify-content-center-between">
					<span>机构简介</span>
					<img class="arrow_right_blank display-block marr10" src="@/assets/img/arrow_right_blank.png" alt="" />
				</div>
			
				<div class="display-flex " >
					<div v-html="dataDetail.desc" class="html-box   paddlr10">
					</div>
				
				</div>
			</div>
		</div>

		<div class="mart10">
			<div class="w1200 margin-center bg-white cure-pointer">
				<div class="serve-title fz16 co-333333 h40 justify-content-center-between font-blod"
					@click="toServce(dataDetail.services)">
					<span>服务项目</span>
					<img class="arrow_right_blank display-block" src="@/assets/img/arrow_right_blank.png" alt="" />
				</div>
				<div>
					<Service :list="dataDetail.services" :type='2'></Service>
				</div>
			</div>
		</div>

		<div class="mart10">
			<div class="w1200 justify-content-center-between bg-white margin-center swiper-indexs">
				<swiper :slidesPerView="1" :spaceBetween="30" :loop="true" :autoplay="false" :pagination='pagination'
					:navigation="navigation" class="sin-mySwiper" v-if="dataDetail.cases.length > 0">
					<swiper-slide class="slider-box" v-for="(item,index) in dataDetail.cases" :key='index'
						@click="delect(item)">
						<el-image style='width: 100%;' :src="item.image" lazy fit="cover"></el-image>
						<div class="absolute left0 top0 right0 bottom0 center img-box flex-column">
							<div class="fz16 co-white font-blod">{{item.title}}</div>
							<div class="fz14 co-CCCCCC mart8 w240 text-center">{{item.mini_title}} </div>
						</div>
					</swiper-slide>
					<div class="swiper-button-prev"></div>
					<div class="swiper-button-next"></div>
				</swiper>

				<swiper :slidesPerView="1" :spaceBetween="30" :loop="true" :autoplay="false" :pagination='pagination'
					:navigation="navigation" class="sin-mySwiper" v-if="dataDetail.student_works.length > 0">
					<swiper-slide class="slider-box" v-for="(item,index) in dataDetail.student_works" :key='index'  @click="preventMove(index,dataDetail.student_works)">
						<el-image style='width: 100%;' :src="item" lazy  fit="cover"></el-image>
						<div class="absolute left0 top0 right0 bottom0 center img-box flex-column">
							<div class="fz16 co-white font-blod">学生作品</div>
						</div>
					</swiper-slide>
					<div class="swiper-button-prev"></div>
					<div class="swiper-button-next"></div>
				</swiper>
				
			</div>
		</div>

		<el-dialog title="填写报名信息" v-model="centerDialogVisible" top="0" custom-class="model-box" width="550px" center>
			<div class="h54 paddl20 paddr19 align-items-center display-flex">
				<div class="name">
					<span class="fz16 co-FE3000">*</span>
					<span class="fz16 co-333333 marl8 name-text">名称</span>
				</div>
				<div class="input-area marl20">
					<input class="fz16 co-333333 popup-input h100 w100" v-model="listObj.name" placeholder="请输入名称" />
				</div>
			</div>
			<div class="h54 paddl20 paddr19 align-items-center">
				<div class="name">
					<span class="fz16 co-FE3000">*</span>
					<span class="fz16 co-333333 marl8 name-text">联系电话</span>
				</div>
				<div class="input-area marl20">
					<input class="fz16 co-333333 popup-input h100 w100" v-model="listObj.contact" type="text"
						maxlength="11" placeholder="请输入你的联系电话" />
				</div>
			</div>
			<div class="h54 paddl20 paddr19 align-items-center">
				<div class="name">
					<span class="fz16 co-FE3000">*</span>
					<span class="fz16 co-333333 marl8 name-text">微信号</span>
				</div>
				<div class="input-area marl20">
					<input class="fz16 co-333333 popup-input h100 w100" placeholder="请输入微信号"
						v-model="listObj.wechat_number" />
				</div>
			</div>
			<div class="h54 paddl20 paddr19 align-items-center">
				<div class="name">
					<span class="fz16 co-FE3000">*</span>
					<span class="fz16 co-333333 marl8 name-text">作品集</span>
				</div>
				<div class="input-area marl20">
					<input class="fz16 co-333333 popup-input h100 w100" placeholder="请输入作品集链接"
						v-model="listObj.works" />
				</div>
			</div>
			<div class=" paddl20 paddr19  display-flex">
				<div class="name " style="width:105px;display: inline-block;">
					<span class="fz16 co-FE3000">*</span>
					<span class="fz16 co-333333 marl8 name-text">作品图片</span>
				</div>
				<div style="width: 400px;display: inline-block;">
					<el-upload class="avatar-uploader" action="https://yunansi.togy.com.cn/api/upload" :limit="9"
						multiple list-type='picture-card' :on-success="handleAvatarSuccess1"
						:before-upload="beforeAvatarUpload" :on-exceed="handleExceed"
						:on-preview="handlePictureCardPreview">
						<i class="el-icon-plus"></i>
					</el-upload>
				</div>
			</div>
			<div class="h54 paddl20 paddr19 align-items-center">
				<div class="name">
					<span class="fz16 co-FE3000">*</span>
					<span class="fz16 co-333333 marl8 name-text">预约时间</span>
				</div>
				<div class=" marl20">
					<!-- <input class="fz16 co-333333 popup-input h100 w100" placeholder="请选择预约时间"  disabled v-model="itme" /> -->
					<el-date-picker v-model="listObj.order_time" @change='datePickerChange' type="date"
						placeholder="请选择预约时间">
					</el-date-picker>
				</div>
			</div>
			<div class="paddb20 mart15">
				<div class="sure-btn fz14 co-010085 center cure-pointer center" @click="submit">提交</div>
			</div>
		</el-dialog>
		<div v-loading.fullscreen.lock="fullscreenLoading"></div>
	</div>
	<el-dialog v-model="dialogVisible" width='800px'>
		<img style="width: 100%;" :src="dialogImageUrl" alt="">
	</el-dialog>
	<!-- 轮播图浴帘 -->
	<div id="mask" v-if="mask">
	    <div class="swiper">
	        <el-carousel trigger="click" height="617px" :interval='2000' arrow="always"  
	            :initial-index='clickindex'>
	            <el-carousel-item v-for="item in templateinfo" :key="item">
	                <el-image style="width: 100%;height: 100%;" fit="scale-down" :src="item" >
	                </el-image>
	            </el-carousel-item>
				<div class="icon-position " @click="stopcick">
				     <i class="el-icon-close" style="font-size: 36px;color: #969696;"></i>
				 </div>
	        </el-carousel>
			
	    </div>
	   
	</div>
	<tgshare ref="sharePopup" :dataObj="dataObj"
		:shareUrl="'www.archipocket.com/#/SincerityDetail?id='+id+'&type='+type"></tgshare>
</template>

<script>
	import {
		useRoute
	} from 'vue-router'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';
	import 'swiper/swiper.less';

	import "swiper/components/pagination/pagination.min.css";
	import "swiper/components/navigation/navigation.min.css";

	import SwiperCore, {
		Autoplay,
		Pagination,
		Navigation
	} from 'swiper/core';
	SwiperCore.use([Autoplay, Pagination, Navigation]);
	import {
		$on,
		$emit
	} from 'vue-happy-bus'
	export default {
		name: 'SincerityDetail',
		components: {
			Swiper,
			SwiperSlide,
		},
		data() {
			return {
				mask: false,
				clickindex:0,
				templateinfo:[],
				dialogVisible: false,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
							
				autoplay: {
					"delay": 2500,
					"disableOnInteraction": false
				}, // 自动轮播
				pagination: {
					"clickable": true,
					"bulletActiveClass": "box-01-active",
					"type": 'bullets'
				}, // 分页
				centerDialogVisible: false, // 在线咨询弹窗
				isfollow: false, // 是否关注
				name: '', // 名称
				tel: '', // 电话
				wechat_number: '', // 微信号
				mId: '', //机构id
				dataDetail: {
					"id": 3,
					"title": "",
					"image": "",
					"is_advanced": 1,
					"favorite": 0,
					"comment": 0,
					"mini_title": "",
					"images": [],
					"view": 0,
					"is_favorite": 0,
					"is_collect": 0,
					"collect": 0,
					"atlas": [],
					"welfare": [],
					"desc": "",
					"address": [],
					"services": [],
					"cases": [],
					"student_works": [],
					"is_reserve": 0
				}, //机构详情
				obj: {},
				isShare: false,
				dataObj: {},
				detailId: '', //机构id
				fullscreenLoading: true, //加载中
				upimg: [],
				listObj: {
					name: '',
					contact: '',
					wechat_number: '',
					works: '',
					works_image: [],
					order_time: ""


				},
				dialogImageUrl: '',
				id: "",
				type: ""
			}
		},
		created() {

			let id = useRoute().query.id;
			this.detailId = id;
			this.id = id;
			this.$req({
				method: 'get',
				url: '/api/agency/detail',
				data: {
					id: this.detailId,
				},
				success: res => {
					this.fullscreenLoading = false;
					this.isfollow = res.is_collect
					console.log(res, "机构详情")
					this.dataDetail = res
					this.dataObj = { //分享参数
						title: res.title,
						id: this.id,
					}
					this.type = res.type
				},
				fail: error => {
					this.$message(error.data.message)
					this.fullscreenLoading = false;
				}
			});

		},
		mounted() {
			let data = useRoute().query;
			if (data.share == 1) {
				let obj = {
					id: data.id,
					type: data.type,
					uuid: localStorage.getItem('uuid'),
					share_uid: data.userId, //分享者uid
					share_time: data.shareTime
				}
				console.log(obj, '分享参数')
				this.$req({
					method: 'put',
					url: '/api/member/share',
					data: {
						...obj
					},
					success: res => {
						console.log(res)
					},
					fail: error => {
						console.log(error)
					}
				});
			}
		},
		methods: {
			stopcick() {
			    document.body.style.overflow = "visible";
			    this.mask = !this.mask;
			},
			preventMove(index,imglist) {
				this.templateinfo = [];
			    document.body.style.overflow = "hidden";
			    this.clickindex = index;
			    this.mask = !this.mask;
				this.templateinfo =imglist
				console.log(this.templateinfo )
			},
			rightClick() {
				this.$refs.sharePopup.open()
			},
			beforeAvatarUpload(file) {
				const isLt4M = file.size / 1024 / 1024 < 4;
				if (!isLt4M) {
					this.$message.error('上传图片大小不能超过 4MB!');
				}
				return isLt4M;

			},
			selectDate(dateData) {
				let date = new Date(dateData)
				let y = date.getFullYear()
				let m = date.getMonth() + 1
				m = m < 10 ? ('0' + m) : m
				let d = date.getDate()
				d = d < 10 ? ('0' + d) : d
				const time = y + '-' + m + '-' + d;
				this.listObj.order_time = time;
			},
			datePickerChange(event) {
				this.selectDate(event)
			},
			handleAvatarSuccess1(res) {
				this.listObj.works_image.push(res.data)

			},
			handleExceed(files, fileList) {
				this.$notify({
					title: '提示',
					message: `图片不能超过9张`,
					type: 'warning'
				});
			},
			openadmin() {
				$emit('openadmin', '打开客服');
			},
			// 关注
			follow() {
				if (this.isfollow) {
					this.isfollow = !this.isfollow;
					this.followRequest()
				} else {
					this.isfollow = !this.isfollow;
					this.followRequest()
				}
			},
			handlePictureCardPreview(file) {

				this.dialogImageUrl = file.response.data;
				console.log(this.dialogImageUrl)
				this.dialogVisible = true;
			},
			followRequest() {
				this.$req({
					method: 'post',
					url: '/api/mapleCollection',
					data: {
						id: this.detailId,
						type: 'agency_collect'
					},
					success: res => {
						if (this.isfollow) {
							this.$message.success('已关注')
						} else {
							this.$message('取消关注')
						}
					},
					fail: error => {
						this.$message(error.message)
						this.isfollow = !this.isfollow
					}
				});
			},
			// 显示免费预约弹窗
			online() {
				this.centerDialogVisible = true
			},
			// 提交
			submit() {
				if (!this.listObj.name) {
					this.$notify({
						title: '提示',
						message: '请输入姓名',
						type: 'warning'
					});
					return false
				}
				var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
				if (!myreg.test(this.listObj.contact)) {
					this.$notify({
						title: '提示',
						message: '请输入手机号',
						type: 'warning'
					});
					return false;
				}
				var wx = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/;
				if (!wx.test(this.listObj.wechat_number)) {
					this.$notify({
						title: '提示',
						message: '微信号不能使用中文,必须以字母开头而且长度要达到6位',
						type: 'warning'
					});
					return false;
				}


				if (!this.listObj.works) {
					this.$notify({
						title: '提示',
						message: '请输入作品集',
						type: 'warning'
					});
					return false
				}

				if (this.listObj.works_image.length <= 0) {
					this.$notify({
						title: '提示',
						message: '请上传作品图片',
						type: 'warning'
					});
					return false
				}
				if (!this.listObj.order_time) {
					this.$notify({
						title: '提示',
						message: '请选择时间',
						type: 'warning'
					});
					return false
				}
				this.$req({
					method: 'post',
					url: '/api/agency/course',
					data: {
						agency_id: this.detailId,
						...this.listObj
					},
					success: res => {
						this.centerDialogVisible = false
						this.$notify({
							title: '成功',
							message: '提交成功',
							type: 'success'
						});
						this.listObj.order_time = ''
					},
					fail: error => {
						// if (error.data.code == 422) {
						this.$message({
							message: error.data.message,
							type: 'warning'
						});
						// }
						this.listObj.order_time = ''
						this.centerDialogVisible = false
					}
				});


			},
			delect(item) {
				console.log(item)
				// this.index = index;
				// this.$refs.SureCancelPopup.open();
				this.$router.push({
					path: '/Detail',
					query: {
						id: item.id,
						type: 3,
					}
				})
			},
			// 跳转服务项目
			godetails(id) {

				this.$router.push({
					path: '/Introduction',
					query: {
						id: id
					}
				})
			},
			toServce(item) {
				let data = JSON.stringify(item)
				this.$router.push({
					path: '/ServiceItem',
					query: {
						data: data,
						type: 2
					}
				})
			},
		}
	}
</script>

<style  lang="less" >
	.icon-position {
	    position: absolute;
	    top: 2%;
	    right: 2%;
	    z-index: 999;
	    display: block;
	}
	#mask {
	    position: fixed;
	    width: 100%;
	    height: 100%;
	    top: 0;
	    left: 0;
	    z-index: 9999;
	    background-color: rgba(0, 0, 0, .7);
	
	    .swiper {
	        position: absolute;
	        top: 50%;
	        left: 50%;
	        transform: translate(-50%, -50%);
	        width: 1280px;
	        height: 600px;
	        overflow: hidden;
	        border-radius: 10px;
			background-color: #000000;
	    }
	}
	.swiper-indexs {
		.swiper-slide {
			text-align: center;
			font-size: 18px;
			background: #fff;
		
			/* Center slide text vertically */
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			-webkit-align-items: center;
			align-items: center;
		}
	
		
		.swiper-button-next,
		.swiper-button-prev {
			position: absolute;
			top: 50%;
			width: calc(var(--swiper-navigation-size)/ 44 * 27);
			height: var(--swiper-navigation-size);
			margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
			z-index: 10;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--swiper-navigation-color, var(--swiper-theme-color));
		}
		
		.swiper-button-prev {
			width: 30px;
			height: 30px;
			background: url(../../assets/img/prev.png) no-repeat;
			background-position: 0 0;
			background-size: 100%;
			left: 10px;
			right: auto;
		}
		
		.swiper-button-next {
			width: 30px;
			height: 30px;
			background: url(../../assets/img/next.png) no-repeat;
			background-position: 0 0;
			background-size: 100%;
			right: 10px;
			left: auto;
		}
	
		
		.sin-mySwiper {
			width: 590px;
			height: 240px;
		}	
		.swiper-button-prev::after {
			content: '';
		}
		
		.swiper-button-next::after {
			content: '';
		}
		.swiper-slide img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.slider-box:hover .img-box {
			display: flex;
		}
		.img-box {
			background: rgba(0, 0, 0, 0.5);
			transition: all .3s;
			display: none;
		}
	}
	
	.btn-02 {
		width: 84px;
		height: 34px;
		background: #DCFF03;
		border-radius: 3px;

	}

	.el-date-editor.el-input,
	.el-date-editor.el-input__inner {
		width: 365px;
	}

	.form {
		border: 1px solid #e7e7e7;


	}

	.avatar-uploader {
		display: flex;
		flex-wrap: wrap;
	}

	.el-upload--picture-card {
		width: 84px;
		height: 84px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.el-upload-list--picture-card .el-upload-list__item {
		width: 84px;
		height: 84px;
	}

	


	

	.follow-btn {
		padding: 0 10px;
		height: 26px;
		background: #DCFF03;
		border-radius: 17px;
	}

	.folloe_icon {
		width: 15px;
		height: 15px;
	}

	

	.swiper-container {
		margin: 0;
	}

	

	.box-01-active {
		background: #FFFFFF !important;
		opacity: 1 !important;
	}

	



	.main {
		width: 1160px;
		margin: 0 auto;
		margin-top: -40px;
		padding: 0 20px;
		padding-top: 20px;


	}

	.sign-btn {
		width: 128px;
		height: 45px;
		background: #DCFF03;
		border-radius: 4px;
	}

	.su_01 {
		width: 20px;
		height: 20px;
	}

	.de-04 {
		width: 32px;
		height: 32px;
	}

	.label-area {
		padding-top: 14px;
		padding-bottom: 14px;


	}

	.label-item {
		padding: 0 10px;
		min-width: 72px;
		height: 28px;
		background: rgba(220, 255, 3, 0.12);
		border-radius: 3px;
	}

	.arrow_right_blank {
		width: 14px;
		height: 14px;
	}

	.serve-title {
		padding-right: 16px;
		padding-left: 20px;
	}




	.model-box {
		border-radius: 8px;
	}

	.model-box .el-dialog__header {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.model-box .el-dialog__title {
		font-size: 20px !important;
		color: #333333;
	}

	.el-dialog--center .el-dialog__body {
		padding: 0 !important;
	}

	.input-area {
		width: 332px;
		padding-left: 16px;
		padding-right: 16px;
		height: 34px;
		background: #FFFFFF;
		border: 1px solid #CCCCCC;
	}

	.name {
		width: 88px;
	}

	.name-text {
		width: 60px;
		text-align: justify;
		text-align-last: justify;
	}

	.popup-input {
		outline: none;
		border: 0;
		margin: 0;
		padding: 0;
	}

	input::-webkit-input-placeholder {
		color: #999999;
		font-size: 16px;
	}

	input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
		margin: 0;
		-moz-appearance: textfield;
	}

	.sure-btn {
		width: 130px;
		height: 28px;
		background: #DCFF03;
		margin: 0 auto;
		margin-top: 24px;
	}
</style>
